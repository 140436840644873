import { Application, defaultSchema } from '@hotwired/stimulus';

const application = new Application(document.documentElement, {
  ...defaultSchema,
  actionAttribute: 'data-do'
});

application.debug = import.meta.env.DEV;

application.start();

window.$$StimulusApp$$ = application;

export { application };
